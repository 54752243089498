<template>
<svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M11 0.5C11.2531 0.5 11.4623 0.688103 11.4955 0.932153L11.5 1V12.3333C11.5 12.6095 11.2761 12.8333 11 12.8333C10.7469 12.8333 10.5377 12.6452 10.5045 12.4012L10.5 12.3333V1C10.5 0.723857 10.7239 0.5 11 0.5Z" fill="currentColor"/>
  <path d="M7 11.833C7.27614 11.833 7.5 12.0569 7.5 12.333C7.5 12.5861 7.3119 12.7953 7.06785 12.8285L7 12.833H1C0.723857 12.833 0.5 12.6091 0.5 12.333C0.5 12.0799 0.688103 11.8707 0.932153 11.8375L1 11.833H7Z" fill="currentColor"/>
  <path d="M6.99967 9.16699C7.27581 9.16699 7.49967 9.39086 7.49967 9.66699C7.49967 9.92013 7.31157 10.1293 7.06752 10.1625L6.99967 10.167H2.33301C2.05687 10.167 1.83301 9.94313 1.83301 9.66699C1.83301 9.41386 2.02111 9.20466 2.26516 9.17153L2.33301 9.16699H6.99967Z" fill="currentColor"/>
  <path d="M7.00033 6.5C7.27647 6.5 7.50033 6.72386 7.50033 7C7.50033 7.25313 7.31223 7.46233 7.06817 7.49543L7.00033 7.5H3.66699C3.39085 7.5 3.16699 7.27614 3.16699 7C3.16699 6.74687 3.35509 6.53767 3.59915 6.50457L3.66699 6.5H7.00033Z" fill="currentColor"/>
  <path d="M6.99961 3.83301C7.27575 3.83301 7.49961 4.05687 7.49961 4.33301C7.49961 4.58614 7.31151 4.79533 7.06746 4.82844L6.99961 4.83301H4.59961C4.32347 4.83301 4.09961 4.60915 4.09961 4.33301C4.09961 4.07987 4.28771 3.87068 4.53176 3.83757L4.59961 3.83301H6.99961Z" fill="currentColor"/>
  <path d="M10.6461 0.646447C10.8236 0.468935 11.1014 0.452798 11.2971 0.598035L11.3532 0.646447L13.0199 2.31311C13.2151 2.50837 13.2151 2.82496 13.0199 3.02022C12.8424 3.19773 12.5646 3.21387 12.3689 3.06863L12.3128 3.02022L10.9997 1.70733L9.68653 3.02022C9.50907 3.19773 9.23127 3.21387 9.03553 3.06863L8.97947 3.02022C8.80193 2.84271 8.7858 2.56493 8.93107 2.36919L8.97947 2.31311L10.6461 0.646447Z" fill="currentColor"/>
</svg>
</template>

<script>
  export default {
    
  }
</script>

<style lang="scss" scoped>

</style>